import request from '../utils/request.js'

//焦点图
export function banner (query) {
  return request({
    url: '/website/articletable/articleCarouselList',
    method: 'get',
    params: query
  })
}


//列表接口 	模块位置，1:左侧文章列表,2:头条热榜,3:快讯
export function listPart (query) {
  return request({
    url: '/website/articletable/pageList',
    method: 'get',
    params: query
  })
}


//文章详情内容
export function articleDetail(id) {
	return request({
		url: '/website/articletable/detail/'+ id,
		method: 'get',
	})
}

//文章详情右边
export function articleRight(query) {
	return request({
		url: '/website/articletable/detailPageList',
		method: 'get',
		params: query
	})
}



